import './wdyr.js'; // <--- first import per https://github.com/welldone-software/why-did-you-render

// import LogRocket from 'shared/services/LogRocket';
// import setupLogRocketReact from 'logrocket-react';

import AppConfig from 'shared/config-public';

import { createRoot } from 'react-dom/client';
import styled from 'styled-components';

import sayHello from 'utils/sayHello.js';
import reportWebVitals from './reportWebVitals';
// import { BootAnim } from './BootAnim';

// Customer tip portal does NOT need to check for new versions
// because it is not intended to be open for long periods of time,
// unlike the admin portal.
// This flag is checked in shared/services/BackendService
window.DISABLE_VERSION_CHECK = true;

sayHello();

const { vayaChatEmbedConfig } = window;
if (!vayaChatEmbedConfig) {
	// eslint-disable-next-line no-console
	console.warn(
		`No vayaChatEmbedConfig found, not rendering Vaya Embedded Chat`,
	);
} else {
	// This is written thusly expecting a secondary actual "bootstrap" script
	// which will: (a) create the root div, (b) attach it to the current DOM, and then
	// (c) add that to the vayaChatEmbedConfig config, then finally (d) import our parcel-wrapped JS and CSS,
	// which will then render the app into the root div (here in this file)
	// This implies that we will TOTALLY IGNORE and not use the 'index.html' in this repo for normal
	// production use case. However, for dev, it can be useful.
	const { rootElementId } = vayaChatEmbedConfig;

	/**
	 * MAJOR MAJOR HACK
	 * For whatever reason, styled-components don't work unless
	 * we mount one FIRST here. THEN it will work elsewhere in the app.
	 * Without a simple SC here, styled components just...don't...work. WHY?
	 */
	const StyledDiv = styled.div`
		background: transparent;
	`;
	const root = createRoot(document.querySelector(`#${rootElementId}`));
	root.render(<StyledDiv />);

	// Hack done...now render the app like usual
	// root.render(<BootAnim />);

	// Render Boot anim first so something happens while we load the App.js bundle
	import('./App.js').then(({ default: App }) => root.render(<App />));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(AppConfig.buildEnv === 'prod' ? console.log : undefined);
